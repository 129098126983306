import React from "react"
import Slider, { Settings as SlickSettings } from "react-slick"
import { Row, Col } from "src/ui"
import { OfferProps, Offer } from "./Offer"
import { LocaleContext } from "../layout"
import { graphql, StaticQuery } from "gatsby"
import { getFixedImageSrc } from "src/utils/images"

export const OffersSlider: React.FC = () => {
  const { locale } = React.useContext(LocaleContext)

  // getting all pages with offerBlocks field with some content in offerName
  const query = graphql`
    query OfferPages {
      allMdxWithOffers: allMdx(filter: { frontmatter: { offerBlocks: { elemMatch: { offerName: { glob: "*" } } } } }) {
        nodes {
          id
          fields {
            locale
          }
          frontmatter {
            title
            offerBlocks {
              offerName
              offerImage {
                publicURL
                childImageSharp {
                  fixed(width: 400, quality: 90) {
                    ...GatsbyImageSharpFixed
                    srcWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          allMdxWithOffers: { nodes: pages },
        } = data

        const pageForCurrentLanguage = pages.filter(page => page.fields && page.fields.locale === locale)[0]
        const offers: OfferProps[] =
          pageForCurrentLanguage && pageForCurrentLanguage.frontmatter
            ? pageForCurrentLanguage.frontmatter.offerBlocks.map(item => ({
                imageUrl: getFixedImageSrc(item.offerImage),
                title: item.offerName,
              }))
            : []

        const sliderSettings: SlickSettings = {
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          focusOnSelect: true,
          centerMode: true,
          centerPadding: "0",
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        }

        return (
          <section className="section section-default px-0">
            <div className="section-title text-center mb-2 mb-md-5 no-border">
              <h1 className="text-warning">
                {pageForCurrentLanguage &&
                  pageForCurrentLanguage.frontmatter &&
                  pageForCurrentLanguage.frontmatter.title}
              </h1>
            </div>
            <div className="offers-slider overflow-hidden">
              <Row noGutters>
                <Col sm="12" className="">
                  <Slider {...sliderSettings}>
                    {offers.map((offer, index) => (
                      <Offer key={index} {...offer} />
                    ))}
                  </Slider>
                </Col>
              </Row>
            </div>
          </section>
        )
      }}
    />
  )
}
